import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FaTrashAlt, FaFilter, FaSearch } from 'react-icons/fa'; // FaEdit
import { useNavigate } from 'react-router-dom';
// import * as XLSX from 'xlsx';
import LoaderOverlay from '../../LoaderOverlay';
import { toast, ToastContainer } from 'react-toastify';

const MedicineList = () => {
    const navigate = useNavigate();
    const [medicineList,setMedicineListData] = useState([]);
    // const [expandedRow, setExpandedRow] = useState(null);
    // const [currentImageIndex, setCurrentImageIndex] = useState(0);
    // const [deleteConfirmation, setDeleteConfirmation] = useState({ open: false, entryId: null });
    // const [editConfirmation, setEditConfirmation] = useState({ open: false, entryId: null });
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);

    const [pageLimit, setPageLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(true);
    const observer = useRef();
    const [previewImages, setPreviewImages] = useState([]);
    // const [productType, setProductType] = useState('');
    // const categories = ['Select', 'Pain Relief', 'Skin Care', 'Cold & Flu Remedies', 'Digestive Health', 'Vitamins & Supplements', 'Allergy & Sinus Relief', 'Baby Care', 'Drinks & Supplements', 'Glocumeters & Test Strips', 'Women Care', 'Health Devices', 'Ayurveda', 'Personal Care', 'Sexual Wellness', 'Other'];
    const productTypes = ['bottles','capsules','captabs','creams','drops','facewash','gels','implants','infusions','injections','instacaps','kits','lotions','lozenges','MDI','ointments','paste','patches','powders','soaps','solutions','sprays','strips','suppositories','suspensions','syrup','syrups','tabcaps','tablets'];

    const handleIntersection = (entries) => {
        const target = entries[0];
        if (target.isIntersecting && !dataLoading && hasMoreData) {
            setDataLoading(true); // Set dataLoading state to true before fetching
            fetchMedicineDetails();
        }
    };

    useEffect(() => {
        fetchMedicineDetails(); 
    },[]);
    
    useEffect(() => {
        observer.current = new IntersectionObserver(handleIntersection, {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        });
        const targetNode = document.getElementById('lastItem');
        if (targetNode) {
            observer.current.observe(targetNode);
        }
        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, [page, dataLoading]);    

    const fetchMedicineDetails = async () => {
        if(!hasMoreData){
            return;
        }
        const params = new URLSearchParams({
            page: page,
            limit: pageLimit
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/medicine-list?${params.toString()}`);
            if (response.status == 200) {
                const data = await response.json();
                setMedicineListData((prevData) => [...prevData, ...data]);
                if (data.length < pageLimit) {
                    setHasMoreData(false);
                }
                setDataLoading(false);
                setPage((prevPage) => prevPage + 1);
                const updatedPreviewImages = {};
                data.forEach(item => {
                    updatedPreviewImages[item.sku] = {
                        firstImageUrl: item.firstImgUrl || '',
                        secondImageUrl: item.secondImageUrl || '',
                        thirdImageUrl: item.thirdImageUrl || ''
                    };
                });
                setPreviewImages(prevPreviewImages => ({ ...prevPreviewImages, ...updatedPreviewImages }));
            } else if(response.status == 202){
                // no data found
            } else {
                console.error('Failed to fetch inventory data');
            }
        } catch (error) {
            console.error('Error fetching inventory data:', error);
        }
    };

    const handleSave = async (sku, index) => {
        try {
            const formData = new FormData();
            for (let key in medicineList[index]) {
                if (key === 'firstImgUrl' && typeof previewImages[sku].firstImageUrl !== 'string') {
                    const fileName = `${sku}-img0.${previewImages[sku].firstImageUrl.name.split('.').pop()}`;
                    formData.append('firstImgUrl', previewImages[sku].firstImageUrl, fileName);
                } 
                else if (key === 'secondImageUrl' && previewImages[sku].secondImageUrl !== null && typeof previewImages[sku].secondImageUrl !== 'string') {
                    const fileName = `${sku}-img1.${previewImages[sku].secondImageUrl.name.split('.').pop()}`;
                    formData.append('secondImageUrl', previewImages[sku].secondImageUrl, fileName);
                } else if (key === 'thirdImageUrl' && previewImages[sku].thirdImageUrl !== null && typeof previewImages[sku].thirdImageUrl !== 'string') {
                    const fileName = `${sku}-img2.${previewImages[sku].thirdImageUrl.name.split('.').pop()}`;
                    formData.append('thirdImageUrl', previewImages[sku].thirdImageUrl, fileName);
                } 
                else {
                    formData.append(key, medicineList[index][key]);
                }
            }
            const response = await axios.post('http://localhost:3001/api/update-medicine', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                toast.success('Item updated successfully');
            } else {
                toast.error('Failed to update item');
            }
        } catch (error) {
            console.error('Error updating item:', error);
            toast.error('Failed to update item');
        }
    };

    const handleInputChange = (index, field, value) => {
        setMedicineListData(prevList => {
            const newList = [...prevList];
            newList[index][field] = value;
            return newList;
        });
    };

    const handleRemoveImage = (sku, imageIndex) => {
        setPreviewImages(prevPreviewImages => {
            const newImages = { ...prevPreviewImages };
            if (newImages[sku]) {
                newImages[sku] = { ...newImages[sku] };
                newImages[sku][imageIndex] = null;
            }
            return newImages;
        });
    };

    const handleUploadImage = async (sku, imageIndex, file) => {
        setPreviewImages(prevPreviewImages => {
            const updatedImages = { ...prevPreviewImages };
            if (updatedImages[sku]) {
                updatedImages[sku] = { ...updatedImages[sku] };
                updatedImages[sku][imageIndex] = file;
            } else {
                updatedImages[sku] = { [imageIndex]: file };
            }
            return updatedImages;
        });
    };    

    return (
        <div className="verify-section w-full h-full relative">
            <LoaderOverlay loading={loading} />
            <div className="flex flex-col md:flex-row h-full text-gray-600">
                <div className="w-full h-full overflow-y-scroll">
                    <div className="pt-8 pb-4 mx-8">
                        <div className="md:flex md:flex-row gap-2">
                            <div className="flex flex-row justify-between">
                                <h2 className="text-xl font-semibold">Inventory</h2>
                            </div>
                            <div className="md:flex flex-row justify-end w-full gap-4">
                                <div className="w-6/12">
                                    <div className="border-2 rounded-full px-2 py-1 flex flex-row items-center gap-2">
                                        <label htmlFor="searchField" className="font-medium text-sm text-gray-400"><FaSearch /></label>
                                        <input id="searchField" type="text" placeholder="Quick Search" className="px-2 text-sm w-full focus:outline-none focus:border-blue-500" />
                                    </div>
                                </div>
                                <div className="w-4/12 flex items-center gap-2 justify-end">
                                    <div className="border-2 rounded-md cursor-pointer px-2 text-lg py-1 text-blue-500 hover:text-white hover:bg-blue-500 duration-200">
                                        <FaFilter />
                                    </div>
                                    <div className="border-2 rounded-md cursor-pointer px-2 w-28 text-sm py-1 text-blue-500 hover:text-white hover:bg-blue-500 duration-200" >
                                        + Add Product
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 text-sm">
                            {medicineList.map((item,index) => (
                                <div key={item.sku} className="bg-white p-2 mb-2 flex flex-col gap-1 shadow-md rounded-md" id={index === medicineList.length - 1 ? 'lastItem' : null}>
                                    <div className="flex flex-row">
                                        <label className="w-2/12 block text-gray-400 pt-2 font-medium text-sm">Prodcut Name</label>
                                        <input type="text" className="w-10/12 border-b-2 px-1 bg-blue-50 border-gray-300 focus:outline-none focus:border-blue-500" value={item.productName} onChange={(e) => handleInputChange(index, 'productName', e.target.value)} />
                                    </div>
                                    <div className="flex flex-row">
                                        <label className="w-2/12 block text-gray-400 pt-2 font-medium text-sm">Composition</label>
                                        <input type="text" className="w-10/12 border-b-2 px-1 bg-blue-50 border-gray-300 focus:outline-none focus:border-blue-500" value={item.composition} onChange={(e) => handleInputChange(index, 'composition', e.target.value)} />
                                    </div>
                                    <div className="flex flex-row">
                                        <label className="w-2/12 block text-gray-400 pt-2 font-medium text-sm">Marketer</label>
                                        <input type="text" className="w-10/12 border-b-2 px-1 bg-blue-50 border-gray-300 focus:outline-none focus:border-blue-500" value={item.brand} onChange={(e) => handleInputChange(index, 'brand', e.target.value)} />
                                    </div>
                                    <div className="flex flex-row">
                                        <label className="w-2/12 block text-gray-400 pt-2 font-medium text-sm">Category</label>
                                        <select className="w-2/12 border-b-2 border-gray-300 px-1 bg-blue-50 focus:outline-none focus:border-blue-500" onChange={(e) => handleInputChange(index, 'category', e.target.value)}>
                                            <option>{item.category}</option>
                                        </select>
                                        <div className="w-1/12"></div>
                                        <div className="w-3/12 flex flex-row gap-2 items-end justify-between">
                                            <label className="block text-gray-400 pt-2 font-medium text-sm">Prescription Required</label>
                                            <div className="flex flex-row">
                                                <div className="flex items-center">   
                                                    <input type="radio" id={`prescriptionYes${item.sku}`} name={`prescriptionStatus${item.sku}`} className="w-4 h-4 mb-[2px] shadow-sm" checked={item.prescriptionStatus === true} onChange={() => handleInputChange(index, 'prescriptionStatus', true)} />
                                                    <label htmlFor={`prescriptionYes${item.sku}`} className="ml-1 mr-4">Yes</label>
                                                </div>
                                                <div className="flex items-center">
                                                    <input type="radio" id={`prescriptionNo${item.sku}`} name={`prescriptionStatus${item.sku}`} className="w-4 h-4 mb-[2px] shadow-sm" checked={item.prescriptionStatus === false} onChange={() => handleInputChange(index, 'prescriptionStatus', false)} />
                                                    <label htmlFor={`prescriptionNo${item.sku}`} className="ml-1">No</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-1/12"></div>
                                        <div className="w-3/12 flex flex-row gap-2 items-end justify-between">
                                            <label className="block text-gray-400 pt-2 font-medium text-sm">Online Sell</label>
                                            <div className="flex flex-row">
                                                <div className="flex items-center">   
                                                    <input type="radio" id={`onlineSellYes${item.sku}`} name={`onlineSell${item.sku}`} className="w-4 h-4 mb-[2px] shadow-sm" checked={item.onlineSellStatus === true} onChange={() => handleInputChange(index, 'onlineSellStatus', true)} />
                                                    <label htmlFor={`onlineSellYes${item.sku}`} className="ml-1 mr-4">Yes</label>
                                                </div>
                                                <div className="flex items-center">
                                                    <input type="radio" id={`onlineSellNo${item.sku}`} name={`onlineSell${item.sku}`} className="w-4 h-4 mb-[2px] shadow-sm" checked={item.onlineSellStatus === false} onChange={() => handleInputChange(index, 'onlineSellStatus', false)} />
                                                    <label htmlFor={`onlineSellNo${item.sku}`} className="ml-1">No</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <label className="w-2/12 block text-gray-400 pt-2 font-medium text-sm">Product Type</label>
                                        <select value={item.productType} className="w-2/12 border-b-2 px-1 bg-blue-50 border-gray-300 focus:outline-none focus:border-blue-500" onChange={(e) => handleInputChange(index, 'productType', e.target.value)}>
                                            {productTypes.map((type) => (
                                                <option key={type} value={type}>
                                                    {type}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="w-1/12"></div>
                                        <label className="w-1/12 block text-gray-400 pt-2 font-medium text-sm">Units Per Pack</label>
                                        <input type="text" className="w-2/12 border-b-2 bg-blue-50 text-right  px-1 border-gray-300 focus:outline-none focus:border-blue-500" value={item.unitsPerPack} onChange={(e) => handleInputChange(index, 'unitsPerPack', e.target.value)} />
                                        <div className="w-1/12"></div>
                                        <label className="w-1/12 block text-gray-400 pt-2 font-medium text-sm">Quantity</label>
                                        <div className="w-2/12 flex flex-row gap-2">
                                            <input type="text" className="border-b-2 w-32 bg-blue-50 text-right  px-1 border-gray-300 focus:outline-none focus:border-blue-500" value={item.quantity} onChange={(e) => handleInputChange(index, 'quantity', e.target.value)} />
                                            <select className="border-b-2 border-gray-300 bg-blue-50 focus:outline-none focus:border-blue-500" value={item.unit} onChange={(e) => handleInputChange(index, 'unit', e.target.value)}>
                                                <option value="">select</option>
                                                <option value="mg">mg</option>
                                                <option value="mcg">mcg</option>
                                                <option value="ml">ml</option>
                                                <option value="gm">gm</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <label className="w-2/12 block text-gray-400 pt-2 font-medium text-sm">Storage</label>
                                        <input type="text" className="w-10/12 border-b-2 bg-blue-50 px-1 border-gray-300 focus:outline-none focus:border-blue-500" value={item.storage} onChange={(e) => handleInputChange(index, 'storage', e.target.value)} />
                                    </div>
                                    <div className="flex flex-row">
                                        <label className="w-2/12 block text-gray-400 pt-2 font-medium text-sm">Description</label>
                                        <textarea className="w-10/12 border-b-2 bg-blue-50 border-gray-300 px-1 focus:outline-none focus:border-blue-500" rows={3} value={item.description} onChange={(e) => handleInputChange(index, 'description', e.target.value)} ></textarea>
                                    </div>
                                    <div className="flex flex-row">
                                        <label className="w-2/12 block text-gray-400 pt-2 font-medium text-sm">Images</label>
                                        <div className="w-10/12 flex flex-row justify-between">
                                            <div className="flex flex-row gap-4">
                                                {Object.keys(previewImages[item.sku] || {}).map((imageUrlKey, imageIndex) => (
                                                    <div>
                                                        <div key={imageIndex} className="flex flex-col items-center shadow-md rounded-md border-[1px] hover:shadow-lg hover:scale-95 duration-200">
                                                            {previewImages[item.sku][imageUrlKey] ? (
                                                                <>
                                                                    <img src={typeof previewImages[item.sku][imageUrlKey] === 'string' ? previewImages[item.sku][imageUrlKey] : URL.createObjectURL(previewImages[item.sku][imageUrlKey])} className="w-16 h-16 rounded-t-md cursor-pointer" />
                                                                    <FaTrashAlt className="text-red-500 p-[2px] my-1 cursor-pointer hover:scale-110 duration-100" onClick={() => handleRemoveImage(item.sku, imageUrlKey)} />
                                                                </>
                                                            ) : (
                                                                <input type="file" className="text-sm border-[1px] rounded-sm px-1 py-1" onChange={(e) => handleUploadImage(item.sku, imageUrlKey, e.target.files[0])} />
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div>
                                                <button className="text-sm bg-green-500 text-white font-semibold hover:bg-green-600 rounded-sm px-6 py-2 shadow-md" onClick={() => handleSave(item.sku, index)}>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {dataLoading && <p>Loading...</p>}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default MedicineList;