import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logoWhite from '../img/logoNameWhite.png';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess, loginFailure } from '../store/slices/authSlice';

const AdminLogin = () => {
  const navigate = useNavigate();

  // Declare and initialize state variables
  const [loginUserName, setLoginUserName] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [loginUserNameValid, setLoginUserNameValid] = useState(true);
  const [loginUserNameExistError, setLoginUserNameExistError] = useState(null);
  const dispatch = useDispatch();

  const inputTypeLogin = showLoginPassword ? 'text' : 'password';
  const loginUserNameBorderColor = loginUserNameValid ? 'shadow-none border-gray-300' : 'shadow-bs border-red-500';

  const toggleLoginPasswordVisibility = () => {
    setShowLoginPassword(!showLoginPassword);
  };

  const handleLogin = async () => {
    setLoginUserNameExistError(null);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`, {
        loginUserName,
        loginPassword,
      });

      if (response.status === 200 && response.data.message === 'Invalid username or password') {
        setLoginUserNameExistError(displayErrorWithLoginOptionMain(1));
      } else if (response.status === 200 && response.data.message === 'Login successful') {
        dispatch(loginSuccess(response.data));
        sessionStorage.setItem('adminId', JSON.stringify(response.data.adminId));
        navigate('/admin-dashboard');
      } else if (response.status === 201) {
        // 2FA code sent
        // setLoginStep(2);
      } else {
        setLoginUserNameExistError(displayErrorWithLoginOptionMain(2));
      }
    } catch (error) {
      dispatch(loginFailure('Invalid credentials'));
      console.error(error);
    }
  };

  const handleLoginPasswordChange = (e) => {
    const value = e.target.value;
    var newValue = value.replace(/\s/g, '');
    setLoginPassword(newValue);
  };

  const handleLoginUserNameChange = (e) => {
    const value = e.target.value;
    var newValue = value.replace(/\s/g, '');
    setLoginUserName(newValue);
  };

  const handleLoginUserNameFocus = () => {
    setLoginUserNameValid(true);
  };

  const displayErrorWithLoginOptionMain = (mode) => {
    return (
      <span className="text-red-500 text-xs font-medium">
        Username or Password is incorrect!
      </span>
    );
  };

  return (
    <div className="flex flex-col min-h-screen">
      <header className="bg-sky-900 text-white h-12 md:h-16 flex flex-col justify-center px-4 md:px-8">
        <div className="flex items-center">
          <h1 className="text-2xl font-semibold mr-1 md:mr-2">
            <img src={logoWhite} alt="MediSeen" className="md:w-32 w-28" />
          </h1>
          {/* <p className="md:text-sm text-xs text-gray-300 relative top-1">Partners</p> */}
        </div>
      </header>
      <div className="flex flex-grow items-center justify-center bg-gray-100 pt-0">
        <div className="p-8 max-w-sm w-full pt-0">
          <div>
            <div className="flex justify-center items-center mb-4">
              <h2 className="text-xl font-semibold">Admin Login</h2>
            </div>
            <div>
              <input
                type="text"
                className={`w-full p-2 border my-2 rounded-md ${loginUserNameBorderColor}`}
                placeholder="Enter username"
                value={loginUserName}
                onChange={handleLoginUserNameChange}
                onFocus={handleLoginUserNameFocus}
              />
              <div className="relative mt-2">
                <input
                  type={inputTypeLogin}
                  className="w-full p-2 mb-2 border border-gray-300 rounded-md"
                  placeholder="Enter password"
                  value={loginPassword}
                  onChange={handleLoginPasswordChange}
                />
                <div className="absolute top-0 right-0 h-full flex items-center pr-2 cursor-pointer" onClick={toggleLoginPasswordVisibility}>
                  {showLoginPassword ? <BsEyeSlash /> : <BsEye />}
                </div>
              </div>
              {loginUserNameExistError}
            </div>
            <button
              type="button"
              className="w-full bg-sky-900 text-white p-2 mt-2 rounded-md hover:bg-sky-700 transition duration-300"
              onClick={handleLogin}
            >
              Log in
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
