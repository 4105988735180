import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const AuthGuard = ({ children, redirectTo }) => {
  const adminId = JSON.parse(sessionStorage.getItem('adminId')); // Change to adminId
  const location = useLocation();

  const isAuthenticated = !!adminId; // Use adminId instead of user
  
  if (isAuthenticated) {
    // Redirect authenticated users from login page
    if (location.pathname === '/' || location.pathname === '/admin-login') {
      return <Navigate to={redirectTo} replace />;
    }
  } else {
    // Redirect unauthenticated users from protected routes
    if (location.pathname !== '/') {
      return <Navigate to="/" replace />;
    }
  }

  return children;
};

export default AuthGuard;
