import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/adminDashboard/Dashboard';
import AuthGuard from './components/AuthGuard';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* Public route for login (don't wrap it with AuthGuard) */}
          <Route path="/admin-login" element={<AdminLogin />} />

          {/* Protected route, wrap with AuthGuard */}
          <Route 
            path="/admin-dashboard" 
            element={
              <AuthGuard redirectTo="/admin-login">
                <AdminDashboard />
              </AuthGuard>
            } 
          />

          {/* Default route for unknown paths, redirect to login */}
          <Route path="*" element={<AdminLogin />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
